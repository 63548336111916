import { Component } from '@angular/core';
import { McSettingService } from '../../services/mc-setting.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenService } from '../../core/auth/token/token.service';
import { UserService } from '../../core/user/user.service';


export interface IMcNavbarGroup {
  title: string;
  items: IMcNavbarItem[];
}

export interface IMcNavbarItem {
  title: string;
  icon: string;
  url: string;
}

@Component({
  selector: 'mc-sider',
  templateUrl: './mc-sider.component.html',
  styleUrls: ['./mc-sider.component.scss'],
})
export class McSiderComponent {

  navbar_conf: IMcNavbarGroup[] = [
    {
      title: '产量数据',
      items: [
        { title: '录入产量', url: '/pg/production/days', icon: 'assets/icon/cart-shopping.svg' },
        { title: '按天汇总', url: '/pg/production/list', icon: 'assets/icon/list.svg' },
        { title: '产量报表', url: '/pg/production/chart', icon: 'assets/icon/chart-line-up.svg' },
      ]
    },
    {
      title: '人力资源',
      items: [
        { title: '人员管理', url: '/pg/employee/dep', icon: 'assets/icon/users.svg' },
        { title: '入 职', url: '/pg/employee/employee', icon: 'assets/icon/user-plus.svg' },
        // { title: '离 职', url: '/pg/employee/leave', icon: 'assets/icon/user-minus.svg' },
        // { title: '组织结构', url: '/pg/employee/department', icon: 'assets/icon/chart-tree-map.svg' },
        { title: '通讯录', url: '/pg/addressbook', icon: 'assets/icon/book-user.svg' },
      ]
    },
    {
      title: '生产资料',
      items: [
        // { title: '合同管理', url: '/pg/employee/ttt1', icon: 'assets/icon/container-storage.svg' },
        // { title: '合同录入', url: '/pg/employee/ttt2', icon: 'assets/icon/file-contract.svg' },
        // { title: '文档录入', url: '/pg/production/days', icon: 'assets/icon/file-arrow-up.svg' },
        { title: '资料库', url: '/pg/archives/catalog', icon: 'assets/icon/database.svg' },
      ]
    },
    {
      title: '系统',
      items: [
        // { title: '权限组', url: '/pg/rbac/group', icon: 'assets/icon/object-subtract.svg' },
        { title: '权限', url: '/pg/rbac/perm', icon: 'assets/icon/shield-cross.svg' },
        { title: '角色', url: '/pg/rbac/role', icon: 'assets/icon/user-shield.svg' },
      ]
    }
  ];

  current_url?: string;

  constructor(public setting_srv: McSettingService, private token_srv: TokenService,
              private router: Router, private route: ActivatedRoute, private user_srv: UserService) {
    // this.setting_srv.notify.subscribe(ret => {
    //   console.log('fff', ret);
    // })
    this.navbar_conf = this.setting_srv.sider_menu;
    console.log('fff-1', this.navbar_conf, this.setting_srv.sider_menu);
    if (this.navbar_conf.length === 0) {
      this.navbar_conf = [
        {
          title: '产量数据',
          items: [
            { title: '录入产量', url: '/pg/production/days', icon: 'assets/icon/cart-shopping.svg' },
            { title: '按天汇总', url: '/pg/production/list', icon: 'assets/icon/list.svg' },
            { title: '产量报表', url: '/pg/production/chart', icon: 'assets/icon/chart-line-up.svg' },
          ]
        }
      ];
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.current_url = event.url;
        return;
        // console.log('this.history--end', event, event.url);
        // const has = this.navbar_conf.some(conf => {
        //   return conf.items.some(item => item.url === event.url);
        // })
        // if (has) {
        //   this.current_url = event.url;
        // }
      }
    });
  }

  protected readonly environment = environment;

  logout() {
    this.user_srv.logout();
    this.router.navigateByUrl(this.user_srv.login_url).then();
  }
}
