import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { mergeMap, Observable, of, throwError } from 'rxjs';

import { checkStatus, getAdditionalHeaders, ReThrowHttpError, toLogin } from './helper';
// import { tryRefreshToken } from './refresh-token';
import { environment } from '../../../environments/environment';
import { StatusCode } from '../http';

function handleData(injector: Injector, ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> {
  // console.log('in--handleData', injector, ev, req, next);
  checkStatus(injector, ev);
  // 业务处理：一些通用操作
  switch (ev.status) {
    case 200:
      // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
      // 例如响应内容：
      //  错误内容：{ status: 1, msg: '非法参数' }
      //  正确内容：{ status: 0, response: {  } }
      // 则以下代码片断可直接适用
      // if (ev instanceof HttpResponse) {
      //   const body = ev.body;
      //   if (body && body.status !== 0) {
      //     const customError = req.context.get(CUSTOM_ERROR);
      //     if (customError) injector.get(NzMessageService).error(body.msg);
      //     return customError ? throwError(() => ({ body, _throw: true }) as ReThrowHttpError) : of({});
      //   } else {
      //     // 返回原始返回体
      //     if (req.context.get(RAW_BODY) || ev.body instanceof Blob) {
      //       return of(ev);
      //     }
      //     // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
      //     return of(new HttpResponse({ ...ev, body: body.response } as any));
      //     // 或者依然保持完整的格式
      //     return of(ev);
      //   }
      // }
      break;
    case 401:
      // if (environment.refreshTokenEnabled && environment.api.refreshTokenType === 're-request') {
      // 401 刷新一次token
      // TODO return tryRefreshToken(injector, ev, req, next);
      // }
      toLogin(injector);
      break;
    case 403:
    case 404:
    case 405:
      // HTTP 405: Method Not Allowed
      console.log('in--HTTP 405: Method Not Allowed', ev.url);
      break;
    case 500:
      // goTo(injector, `/exception/${ev.status}?url=${req.urlWithParams}`);
      break;
    case 502:
    default:
      if (ev instanceof HttpErrorResponse) {
        console.warn('未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起，请参考 https://ng-alain.com/docs/server 解决跨域问题', ev);
      }
      break;
  }
  if (ev instanceof HttpErrorResponse) {
    return throwError(() => ev);
  } else if ((ev as unknown as ReThrowHttpError)._throw) {
    return throwError(() => (ev as unknown as ReThrowHttpError).body);
  } else {
    const res = ev as HttpResponse<any>;
    if (res.body._code_ === StatusCode.TOKEN_NOTEXIST || res.body._code_ === StatusCode.TOKEN_EXPIRED
      || res.body._code_ === StatusCode.TOKEN_ERROR) {
      toLogin(injector);
      return of(null);
    }
    return of(ev);
  }
}

export const defaultInterceptor: HttpInterceptorFn = (req, next) => {
  // console.log('in--defaultInterceptor', req, next);
  // 统一加上服务端前缀
  let url = req.url;
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    const base_url = environment.apiBaseUrl;
    url = base_url + (base_url.endsWith('/') && url.startsWith('/') ? url.substring(1) : url);
  }
  const newReq = req.clone({ url, setHeaders: getAdditionalHeaders(req.headers) });
  const injector = inject(Injector);
  // 提交给下一个拦截器
  return next(newReq).pipe(
    mergeMap(ev => {
      // console.log('in--evev', ev, ev instanceof HttpResponseBase);
      // 允许统一对请求错误处理
      if (ev instanceof HttpResponseBase) {
        return handleData(injector, ev, newReq, next);
      }
      // 若一切都正常，则后续操作
      return of(ev);
      // }),
      // catchError((error: HttpErrorResponse) => {
      //   console.log(123131, error);
      //   // if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)) {
      //     // authService.logout();
      //     // router.navigateByUrl('/auth/login');
      //   // }
      //   return throwError(() => error);
    }),
  );
};
