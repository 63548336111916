import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, zip } from 'rxjs';
import { TokenService } from '../../core/auth/token/token.service';
import { StatusCode } from '../../core/http';
import { McSettingService } from '../mc-setting.service';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  // private settingService = inject(SettingsService);
  // private aclService = inject(ACLService);
  // private titleSrv = inject(TitleService);
  private tokenSrv = inject(TokenService);
  // private tokenSrv: TokenService;
  private http_client = inject(HttpClient);
  // private doc = inject(DOCUMENT);
  // private i18n = inject<I18NService>(ALAIN_I18N_TOKEN);

  constructor(private setting_srv: McSettingService) {
  }

  /**
   * 启动时加载
   */
  init(): Promise<any> {
    console.log('in--StartupService', 'init')
    const token_data = this.tokenSrv.get();
    console.log('via', token_data);
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise(resolve => {
      // resolve(null);
      // return;
      zip(this.http_client.post(`/member/user_info`, {}))
        .pipe(
          // 接收其他拦截器后产生的异常消息
          catchError(([user_info, appData]) => {
            console.log('in-start-init-err', user_info, appData);
            resolve(null);
            return [user_info, appData];
          })
        )
        .subscribe({
          next: ([user_info, appData]) => {
            console.log('in--startupService', 'next', user_info, appData)
            // Load general language data
            if (user_info?._code_ === StatusCode.SUCCESS) {
              this.setting_srv.set_rbac({ ...user_info.data.rbac });
              delete user_info.data.rbac;
              this.setting_srv.set_sider_menu([...user_info.data.user_sider_menu]);
              delete user_info.data.user_sider_menu;
              this.setting_srv.set_user(user_info.data);
            }
            const a = {
              aaa: 123, bbb: 'bbb111', ccc: { ddd: 123, eee: 'eee111' }
            };
            console.log()
          },
          error: () => {
            console.log('in-start-init-err22');
            resolve(null);
          },
          complete: () => {
            console.log('in-start-init-complete');
            resolve(null);
            this.removeLoading();
          }
        });
    });
  }

  private removeLoading(): void {
    // const el = this.doc.querySelector('#j-preloader');
    // if (el) {
    //   el.remove();
    // }
  }
}
