import { ActionCode, IMcFastDetail, McHttpRet, StatusCode } from './model.type';
import { Directive, EventEmitter, inject, Output } from '@angular/core';
import { McHttpClient } from './http.client';
import { deep_merge, deepCopy } from '../util/other/deep';

export interface IMcFastData {
  id?: any;

  [key: string]: any;
}

@Directive()
export class McFastDetail<T extends IMcFastData> implements IMcFastDetail<T> {
  data: T;
  loading = false;
  // 数据加载完成
  load_complete = false;

  @Output() data_change = new EventEmitter<boolean>();

  protected http: McHttpClient;

  protected constructor(public cls_model: new () => T, public api_url: string) {
    this.data = new this.cls_model();
    this.http = inject(McHttpClient);
  }

  async detail(fetch: boolean = true): Promise<boolean> {
    let keeping: Promise<boolean> | boolean;
    keeping = await this.detailing();
    if (!keeping || !fetch) {
      // this.loading_detail = false;
      return this.detailed();
    }
    // 设置loading状态及效果
    this.loading = true;
    // 远程加载
    const status: boolean = await this.fetch_detail();
    keeping = await this.detailed(status);
    this.loading = false;
    return keeping;
  };

  detailed(status?: boolean): Promise<boolean> | boolean {
    return true;
  }

  detailing(): Promise<boolean> | boolean {
    return true;
  }

  fetch_detail(): Promise<boolean> | boolean {
    return this.remote_request(ActionCode.DETAIL);
  }

  async update(): Promise<boolean> {
    this.loading = true;
    let keeping: Promise<boolean> | boolean = await this.updating();
    if (!keeping) {
      this.loading = false;
      return false;
    }
    const status: boolean = await this.remote_update();
    keeping = await this.updated(status);
    // this.mcService.rmMsg(msg_id);
    if (keeping) {
      this.loading = false;
      this.data_change.emit(true);
    }
    return keeping;
  };

  updating(): Promise<boolean> | boolean {
    return true;
  }

  updated(status?: boolean): Promise<boolean> | boolean {
    return true;
  }

  remote_update(): Promise<boolean> | boolean {
    if (!this.data.id) {
      return this.remote_request(ActionCode.INSERT);
    } else {
      return this.remote_request(ActionCode.UPDATE);
    }
  }


  async delete(): Promise<boolean> {
    if (!this.data.id) {
      console.error('删除数据id不存在', this.data);
      return false;
    }
    this.loading = true;
    let keeping: Promise<boolean> | boolean = await this.deleting();
    if (!keeping) {
      this.loading = false;
      return false;
    }
    const status: boolean = await this.remote_delete();
    console.log('delete-00', status);
    keeping = await this.deleted(status);
    // this.mcService.rmMsg(msg_id);
    console.log('delete--end', keeping);
    this.loading = false;
    this.data_change.emit(true);
    return keeping;
  }

  deleting(): Promise<boolean> | boolean {
    return true;
  }

  deleted(status?: boolean): Promise<boolean> | boolean {
    return true;
  }

  remote_delete(): Promise<boolean> | boolean {
    return this.remote_request(ActionCode.DELETE);
  }

  remote_request(action_code?: ActionCode) {
    if (!action_code) {
      action_code = ActionCode.DETAIL;
    }
    const post_data = deepCopy({ _action_: action_code, ...this.data });
    // console.log('zzzz-remote_request', post_data)
    for (const key of Object.keys(post_data)) {
      if (key.startsWith('__')) {
        // 默认已"__"开头的变量为前端使用变量，不提交到后台
        delete post_data[key];
      }
    }
    return new Promise<boolean>((resolve) => {
      this.http.post<McHttpRet<any>>(this.api_url, post_data)
        // {_action_: action_code, ...this.data})
        .subscribe(ret => {
          if (ret._code_ === StatusCode.SUCCESS) {
            // console.log('rrrr', ret.data, this.data);
            // Object.assign(this.data as object, ret.data);
            deep_merge(this.data, ret.data);
            // console.log('rrrr-1', this.data, ret.data.production);
            // this.data = deep_copy(ret.data);
            resolve(true);
          }
          resolve(false);
        });
    });
  }

}
