import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McSiderComponent } from './mc-sider.component';
import { IonicModule } from '@ionic/angular';
import { RouterLink } from '@angular/router';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';


@NgModule({
  declarations: [McSiderComponent],
  exports: [
    McSiderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterLink,
    CdkMenuItem,
    CdkMenu,
    CdkMenuTrigger
  ]
})
export class McSiderModule {
}
