import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcSiderWrapperComponent } from './pc-sider-wrapper.component';
import { McSiderModule } from '../../components/mc-sider/mc-sider.module';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [PcSiderWrapperComponent],
  exports: [PcSiderWrapperComponent],
  imports: [
    CommonModule,
    McSiderModule,
    RouterModule,
    FormsModule,
    IonicModule
  ]
})
export class PcSiderWrapperModule {
}
