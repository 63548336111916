import { Inject, Injectable, Optional } from '@angular/core';
import { MMC_CONFIG, MmcConfig, MmcConfigKey } from './config.type';
import { deepMergeKey } from '../other/deep';

@Injectable({providedIn: 'root'})
export class McConfigService {
  protected config: MmcConfig;

  constructor(@Optional() @Inject(MMC_CONFIG) defaultConfig?: MmcConfig) {
    this.config = {...defaultConfig};
  }

  get<T extends MmcConfigKey>(componentName: T, key?: string): MmcConfig[T] {
    const res = ((this.config[componentName] as { [key: string]: unknown }) || {}) as any;
    return key ? {[key]: res[key]} : res;
  }

  merge<T extends MmcConfigKey>(componentName: T, ...defaultValues: Array<MmcConfig[T]>): MmcConfig[T] {
    return deepMergeKey({}, true, ...defaultValues, this.get(componentName));
  }

  attach<T extends MmcConfigKey>(componentThis: unknown, componentName: T, defaultValues: MmcConfig[T]): void {
    Object.assign(componentThis as any, this.merge(componentName, defaultValues));
  }

  attachKey<T extends MmcConfigKey>(componentThis: unknown, componentName: T, key: string): void {
    Object.assign(componentThis as any, this.get(componentName, key));
  }

  set<T extends MmcConfigKey>(componentName: T, value: MmcConfig[T]): void {
    this.config[componentName] = {...this.config[componentName], ...value};
  }
}
