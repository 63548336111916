import { MmcAuthConfig } from '../../util/config/auth/auth.type';
import { McConfigService } from '../../util/config/config.service';

export const AUTH_DEFAULT_CONFIG: MmcAuthConfig = {
  store_key: `_token`,
  token_invalid_redirect: true,
  token_exp_offset: 10,
  token_send_key: `token`,
  token_send_template: '${token}',
  token_send_place: 'header',
  login_url: '/login',
  ignores: [/\/login/, /assets\//, /passport\//],
  refreshTime: 3000,
  refreshOffset: 6000
};

export function mergeConfig(srv: McConfigService): MmcAuthConfig {
  return srv.merge('auth', AUTH_DEFAULT_CONFIG)!;
}










