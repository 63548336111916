// 状态指令
import { EventEmitter } from '@angular/core';

export enum StatusCode {
  // """ 成功 """
  SUCCESS = 0,
  // """ 失败 """
  FAIL = 1,
  // """ 错误 """
  ERROR = 2,
  // """ 存在 """
  EXIST = 3,
  // """ 不存在 """
  NOTEXIST = 4,
// """ 已提交 """
  SUBMITTED = 5,
// """ 运行中 """
  RUNNING = 6,
// """ TOKEN 认证失败 """
  TOKEN_NOTEXIST = 10,
// """ TOKEN 过期 """
  TOKEN_EXPIRED = 11,
// """ TOKEN 解析失败 """
  TOKEN_ERROR = 12,
}


// 动作指令
export enum ActionCode {
  DETAIL = 1,    // GET
  INSERT = 2,    // 添加
  UPDATE = 3,    // 更新
  DELETE = 4,    // 删除
  DELETE_ALL = 5,    // 批量删除
  ACTIVE = 6,    // 激活
  CHECK = 7,    // 效验审核
  CHANGE = 8,    // 更改
  SORT_UP = 9,    // 上移
  SORT_DOWN = 10,    // 下移
  SORT_TOP = 11,    // 置顶
  SORT_NO_TOP = 12,    // 置底
  GRID = 13,    // 数据表格
  IMPORT = 14,   // 导入
  EXPORT_EXCEL = 15,   // 导出excel
  DOWNLOAD = 16,   // 下载
  SORT_MAP = 17   // 批量排序
}

export interface McHttpRet<T> {
  data: T;
  _code_: StatusCode;
  _msg_: string;
}

export interface IMcFastDetail<T> {
  data: T;

  // 当前loading状态
  loading: boolean;

  /**
   * 获取模型数据详情
   * @param fetch
   */
  detail(fetch: boolean): boolean | Promise<boolean>;

  /**
   * 获取详情数据开始,return:false: 不远程请求数据，直接调用 detailed()
   */
  detailing(): boolean | Promise<boolean>;

  /**
   * 获取数据结束
   * @param status 远程获取数据是否完成 true=完成 false=失败 null=未远程获取数据
   */
  detailed(status?: boolean): boolean | Promise<boolean>;

  /**
   * 远程获取数据
   */
  fetch_detail(): Promise<boolean> | boolean;

  /**
   * 更新数据
   */
  update(): boolean | Promise<boolean>;

  /**
   * 更新数据开始前，return:false: 不远程请求数据，直接调用 updated()
   */
  updating(): boolean | Promise<boolean>;

  /**
   * 更新结束
   * @param status 远程更新是否完成
   */
  updated(status?: boolean): boolean | Promise<boolean>;

  /**
   * 远程更新数据
   */
  remote_update(): Promise<boolean> | boolean;

  ///// utils /////
  // error_tip(error: ValidationErrors | null): string;

  ///// events /////
  // 数据操作事件
  data_change: EventEmitter<boolean>;
}

export interface IMcFastGridOpts {
  page_start: number;
  page_size: number;
  page_index: number;
  search?: string;
  order?: [string, string][];
  // filter?: object;
  // 透传给服务端，不交由fast模型处理
  udata?: Record<string, any>;
  token?: string;
  _action_?: number;
}

export interface IMcFastGrid<T> {
  items: T[];
  opts: IMcFastGridOpts;

  /**
   * 准备分页参数
   * @param page_size
   * @param page_start
   */
  paging(page_size?: number, page_start?: number): void;

  /**
   * 准备筛选参数
   */
  filter(): void;

  /**
   * 远程请求导出数据，基于现在的筛选参数
   */
  export(): void;

  /**
   * 从远程开始获取数据
   */
  fetch(): boolean | Promise<boolean>;

}
