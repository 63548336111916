import { MmcAuthConfig } from './auth/auth.type';
import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { MmcACLConfig } from './acl/acl.type';

export interface MmcConfig {
  auth?: MmcAuthConfig;
  atl?: MmcACLConfig;
}

export type MmcConfigKey = keyof MmcConfig;

export const MMC_CONFIG = new InjectionToken<MmcConfig>('mmc-config', {
  providedIn: 'root',
  factory: MMC_CONFIG_FACTORY
});

export function MMC_CONFIG_FACTORY(): MmcConfig {
  return {};
}

export function provideAlainConfig(config: MmcConfig): EnvironmentProviders {
  return makeEnvironmentProviders([{provide: MMC_CONFIG, useValue: config}]);
}

