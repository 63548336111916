import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { JWTTokenModel } from './jwt.model';
import { isAnonymous, throwErr } from '../base.interceptor';
import { CheckJwt } from '../helper';
import { TokenService } from '../token.service';
import { McConfigService } from '../../../util/config/config.service';
import { mergeConfig } from '../auth.config';

function newReq(req: HttpRequest<unknown>, model: JWTTokenModel): HttpRequest<unknown> {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${model.token}`
    }
  });
}

export const authJWTInterceptor: HttpInterceptorFn = (req, next) => {
  // console.log('in--authJWTInterceptor', req, next);
  const options = mergeConfig(inject(McConfigService));

  if (isAnonymous(req, options)) return next(req);

  const model = inject(TokenService).get<JWTTokenModel>(JWTTokenModel);
  if (CheckJwt(model, options.token_exp_offset!)) return next(newReq(req, model));

  return throwErr(req, options);
};
