import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ITokenService } from './interface';
import { JWTTokenModel } from './jwt/jwt.model';
import { MmcAuthConfig } from '../../util/config/auth/auth.type';
import { TokenService } from './token.service';

/**
 * 检查 `Token` 是否存在
 * @param model
 * @constructor
 */
export function CheckSimple(model: JWTTokenModel | null): boolean {
  return model != null && typeof model.token === 'string' && model.token.length > 0;
}

/**
 * 检查 `Token` 是否过期
 * @param model
 * @param offset
 * @constructor
 */
export function CheckJwt(model: JWTTokenModel, offset: number): boolean {
  // console.log('in--CheckJwt', 'CheckJwt', model, offset)
  // try {
  //   return model != null && !!model.token && !model.isExpired(offset);
  // } catch (err: unknown) {
  //   if (typeof ngDevMode === 'undefined' || ngDevMode) {
  //     console.warn(`${(err as { message: string }).message}, jump to login_url`);
  //   }
  //   return false;
  // }
  return model != null && !!model.token && !model.isExpired(offset);
}

export function ToLogin(options: MmcAuthConfig, url?: string): void {
  const router = inject(Router);
  const token = inject(TokenService) as ITokenService;
  const doc = inject(DOCUMENT);
  token.referrer!.url = url || router.url;
  if (options.token_invalid_redirect === true) {
    setTimeout(() => {
      if (/^https?:\/\//g.test(options.login_url!)) {
        doc.location.href = options.login_url as string;
      } else {
        router.navigate([options.login_url]);
      }
    });
  }
}
