import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, delay, finalize, Observable, of, switchMap, tap } from 'rxjs';

export type MmcHttpHeaders = HttpHeaders | { [header: string]: string | string[] };
export type MmcHttpObserve = 'body' | 'events' | 'response';

/**
 * 封装HttpClient，主要解决：
 * + 优化HttpClient在参数上便利性
 * + 统一实现 loading
 * + 统一处理时间格式问题
 */
@Injectable({ providedIn: 'root' })
export class McHttpClient {
  private lc = 0;

  constructor(private http: HttpClient) {

  }


  /**
   * Get whether it's loading
   *
   * 获取是否正在加载中
   */
  get loading(): boolean {
    return this.lc > 0;
  }

  /**
   * Get the currently loading count
   *
   * 获取当前加载中的数量
   */
  get loadingCount(): number {
    return this.lc;
  }

  parseParams(params: any): HttpParams {
    const newParams: any = {};
    if (params instanceof HttpParams) {
      return params;
    }

    Object.keys(params).forEach(key => {
      let paramValue = params[key];
      // 忽略空值
      if (paramValue == null) return;
      // 将时间转化为：时间戳 (秒)
      // if (
      //   paramValue instanceof Date &&
      //   (dateValueHandling === 'timestamp' || dateValueHandling === 'timestampSecond')
      // ) {
      //   paramValue = dateValueHandling === 'timestamp' ? paramValue.valueOf() : Math.trunc(paramValue.valueOf() / 1000);
      // }
      newParams[key] = paramValue;
    });
    return new HttpParams({ fromObject: newParams });
  }

  private setCount(count: number): void {
    Promise.resolve(null).then(() => (this.lc = count <= 0 ? 0 : count));
  }

  private push(): void {
    this.setCount(++this.lc);
  }

  private pop(): void {
    this.setCount(--this.lc);
  }

  // #region post

  /**
   * **POST Request** Return a `string` type / 返回一个 `string` 类型
   */
  post(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: MmcHttpHeaders;
      observe?: 'body';
      reportProgress?: boolean;
      responseType: 'text';
      withCredentials?: boolean;
      context?: HttpContext;
    }
  ): Observable<string>;

  /**
   * **POST Request** Return a `HttpEvent<T>` type / 返回一个 `HttpEvent<T>` 类型
   */
  post<T>(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: MmcHttpHeaders;
      observe: 'events';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
      context?: HttpContext;
    }
  ): Observable<HttpEvent<T>>;

  /**
   * **POST Request** Return a `HttpResponse<any>` type / 返回一个 `HttpResponse<any>` 类型
   */
  post(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: MmcHttpHeaders;
      observe: 'response';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      context?: HttpContext;
    }
  ): Observable<HttpResponse<any>>;

  /**
   * **POST Request** Return a `any` type / 返回一个 `any` 类型
   */
  post(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: MmcHttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
      context?: HttpContext;
    }
  ): Observable<any>;

  /**
   * **POST Request** Return a JSON type / 返回一个 `JSON` 类型
   */
  post<T>(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: MmcHttpHeaders;
      observe: 'response';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      context?: HttpContext;
    }
  ): Observable<T>;

  post(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: MmcHttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
      context?: HttpContext;
    } = {}
  ): Observable<any> {
    return this.request('POST', url, {
      body,
      params,
      ...options
    });
  }

  // #endregion

  request(
    method: string,
    url: string,
    options: {
      body?: any;
      headers?: MmcHttpHeaders;
      params?: any;
      observe?: MmcHttpObserve;
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
      context?: HttpContext;
    } = {}
  ): Observable<any> {
    if (options.params) {
      options.params = this.parseParams(options.params);
    }
    return of(null).pipe(
      // Make sure to always be asynchronous, see issues: https://github.com/ng-alain/ng-alain/issues/1954
      delay(0),
      tap(() => this.push()),
      switchMap(() => this.http.request(method, url, options)),
      finalize(() => this.pop()),
      catchError(error => {
        const status: number = error.status;
        console.log('upload1001', error, status);
        // this.message.create('error', `HTTP ${status} – 请求失败`);
        return of(null);
      })
    )
  }
}
