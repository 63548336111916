<div class="mc-side-logo">
  <img src="assets/img/logo1.png" alt=""/>
</div>

<div class="mc-side-user">
  <div class="msu-card">
    <div class="msu-card-body">

      <ion-avatar class="msu-avatar" *ngIf="setting_srv.user.avatar">
        <img alt="" [src]="environment.upload+setting_srv.user.avatar.thumbnail"/>
      </ion-avatar>

      <div class="msu-avatar" *ngIf="!setting_srv.user.avatar">
        {{ setting_srv.user.name?.substring(0, 1) }}
      </div>

      <div class="msu-info">
        <strong>{{ setting_srv.user.name }}</strong>
        <p>{{ setting_srv.user.phone }}</p>
      </div>
      <div class="msu-user-btn" [cdkMenuTriggerFor]="menu" [cdkMenuTriggerData]="{$implicit:null}">
        <ion-icon src="assets/icon/ellipsis-vertical.svg"></ion-icon>
      </div>
      <!--  目录菜单  -->
      <ng-template #menu let-node>
        <div class="cdk-menu" cdkMenu>
          <!--          <button class="cdk-menu-item" cdkMenuItem>-->
          <!--            &lt;!&ndash;            (click)="open_dialog(DirectionDialogComponent,{ create:true, node })"&ndash;&gt;-->
          <!--            创建子分类-->
          <!--          </button>-->
          <button class="cdk-menu-item" cdkMenuItem (click)="logout()">退出登录</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="mc-navbar" *ngFor="let conf of navbar_conf">
  <div class="mn-caption mn-item">
    {{ conf.title }}
  </div>
  <div class="mc-navbar-btn-panel">
    <ng-container *ngFor="let item of conf.items">
      <div class="mc-navbar-btn" [class.mc-navbar-btn-hover]="current_url?.startsWith(item.url)"
           [routerLink]="[item.url]">
        <ion-icon [src]="item.icon"></ion-icon>
        <p>{{ item.title }}</p>
      </div>
    </ng-container>
  </div>
</div>


