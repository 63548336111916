import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-pc-sider-wrapper',
  templateUrl: './pc-sider-wrapper.component.html',
  styleUrls: ['./pc-sider-wrapper.component.scss'],
})
export class PcSiderWrapperComponent {

  constructor(public platform: Platform) {

  }

}
