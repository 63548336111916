import { inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { McStorageService } from '../core/cache/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAttachesFile } from '../components/mc-upload/model';

export interface User {
  [key: string]: any;

  /** Name for current user */
  name?: string;
  /** Avatar for current user */
  avatar?: IAttachesFile;
  /** phone for current user */
  phone?: string;

  /** 部门信息 **/
  deps: Record<number, string>;
}

export interface UserRbac {
  role: string[];
  codes: string[];
  paths: string[];
}

export interface SettingsNotify {
  type: 'layout' | 'app' | 'user' | 'rbac' | 'init';
  /** Update `key` name, limited `layout` type */
  name?: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class McSettingService {
  private readonly storage: McStorageService<any> = inject(McStorageService<any>);
  private readonly platform = inject(Platform);
  private _user: User | null = null;
  public rbac: UserRbac = { role: [], codes: [], paths: [] };
  public sider_menu?: any;
  private notify$ = new BehaviorSubject<SettingsNotify>({ type: 'init', value: null });

  constructor() {
  }

  get user(): User {
    if (!this._user) {
      this._user = { ...this.storage.get('user') };
    }
    return this._user as User;
  }

  set_user(value: User): void {
    this._user = value;
    this.storage.set('user', value);
    this.notify$.next({ type: 'user', value });
  }

  set_rbac(value: UserRbac): void {
    this.rbac = value;
    this.notify$.next({ type: 'rbac', value });
  }

  set_sider_menu(value: any) {
    this.sider_menu = value;
    // this.notify$.next({ type: 'sider_menu', value });
  }

  clear_rbac() {
    this.rbac = { role: [], codes: [], paths: [] };
  }

  has_code(code: string): boolean {
    return this.rbac.codes.includes(code);
  }

  has_path(path: string): boolean {
    return this.rbac.paths.includes(path);
  }

  has_role(code: string): boolean {
    return this.rbac.role.includes(code);
  }

  get notify(): Observable<SettingsNotify> {
    return this.notify$.asObservable();
  }

}
