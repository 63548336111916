import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StartupService } from './services/startup/startup.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { defaultInterceptor } from './core/net/default.interceptor';
import { authJWTInterceptor } from './core/auth/token/jwt/jwt.interceptor';
import { MmcConfig, provideAlainConfig } from './core/util/config/config.type';
import { MyHammerConfig } from './hammer-config';
import { NgxEchartsModule } from 'ngx-echarts';
import { PcSiderWrapperModule } from './pages/pc-sider-warpper/pc-sider-wrapper.module';


export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.init();
}

const APP_INIT_PROVIDES = [
  // 平台启动服务
  StartupService,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true
  }
];
const config: MmcConfig = {
  auth: { login_url: '/auth/login' }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    PcSiderWrapperModule,
    IonicModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AppRoutingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    // {provide: HTTP_INTERCEPTORS, useClass: authJWTInterceptor, multi: true},
    provideHttpClient(withInterceptors([defaultInterceptor, authJWTInterceptor])),
    provideAlainConfig(config),
    ...APP_INIT_PROVIDES
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
