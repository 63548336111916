import { HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../auth/token/token.service';
import { McMessageService } from '../../services/mc-message.service';

export interface ReThrowHttpError {
  body: any;
  _throw: true;
}

export const CODEMESSAGE: { [key: number]: string } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};

export function goTo(injector: Injector, url: string): void {
  console.log('in--goTo', `跳转至 ${url}`)
  setTimeout(() => injector.get(Router).navigateByUrl(url));
}

export function toLogin(injector: Injector): void {
  console.log('in--toLogin', `未登录或登录已过期，请重新登录。`);
  injector.get(McMessageService).error(`未登录或登录已过期，请重新登录。`).then();
  goTo(injector, injector.get(TokenService).login_url!);
}

export function getAdditionalHeaders(headers?: HttpHeaders): { [name: string]: string } {
  const res: { [name: string]: string } = {};
  // console.log('获取附件头信息', res);
  // const lang = inject(ALAIN_I18N_TOKEN).currentLang;
  // if (!headers?.has('Accept-Language') && lang) {
  //   res['Accept-Language'] = lang;
  // }
  return res;
}

export function checkStatus(injector: Injector, ev: HttpResponseBase): void {
  if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
    return;
  }
  // const errortext = CODEMESSAGE[ev.status] || ev.statusText;
  console.log('in--checkStatus', `请求错误 ${ev.status}: ${ev.url}`);
  injector.get(McMessageService).error(`请求错误 ${ev.status}: ${ev.url}`);
}
