<ng-container *ngIf="platform.is('mobileweb');else pc_container">
  <!--  默认移动端-->
  <ion-router-outlet [swipeGesture]="platform.is('android')" [animated]="false"></ion-router-outlet>
</ng-container>

<ng-template #pc_container>
  <!--  pc端-->
  <ion-split-pane contentId="main" class="pc-web">
    <ion-menu contentId="main">

      <mc-sider></mc-sider>
      <!--      <dn-record-list class="ion-page"></dn-record-list>-->
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ng-template>



