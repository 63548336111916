import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { StartupGuard } from './services/startup/startup.guard';
import { authJWTCanActivate } from './core/auth/token/jwt/jwt.guard';
import { PcSiderWrapperComponent } from './pages/pc-sider-warpper/pc-sider-wrapper.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth-routing.module').then(m => m.AuthRoutingModule)
      },
      {
        path: 'sysconfig',
        loadChildren: () => import('./pages/sys-admin/sys-admin-routing.module').then(m => m.SysAdminRoutingModule)
      },
      {
        path: 'pg',
        component: PcSiderWrapperComponent,
        canActivate: [StartupGuard, authJWTCanActivate],
        // canActivateChild: [authJWTCanActivateChild],
        loadChildren: () => import('./pages/pages-routing.module').then(m => m.PagesRoutingModule)
      },
      {
        path: 'message/:id',
        loadChildren: () => import('./view-message/view-message.module').then(m => m.ViewMessagePageModule)
      },
      {
        path: '',
        redirectTo: '/pg/production/chart',
        pathMatch: 'full'
      }
    ]
  },
  // {
  //   path: '',
  //   redirectTo: '/pg/pro1duction/days',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
