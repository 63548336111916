import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class McMessageService {
  public is_loading = false;

  // 当前loading组件
  private current_loading_ctrl?: HTMLIonLoadingElement;

  constructor(private loading: LoadingController,
              private toast_ctrl: ToastController, private alert_ctrl: AlertController) {
  }

  async loading_present(message?: string, duration?: number) {
    // console.log('current_loading_ctrl--1', this.current_loading_ctrl);
    if (this.current_loading_ctrl) {
      this.current_loading_ctrl.message = message;
      this.current_loading_ctrl.duration += duration || 30000;
      return;
    }
    this.is_loading = true;
    return await this.loading.create({
      message: message || '正在加载.',
      duration: duration || 30000,
    }).then(ctrl => {
      this.current_loading_ctrl = ctrl;
      ctrl.present().then(() => {
        if (!this.is_loading) {
          this.current_loading_ctrl = undefined;
          ctrl.dismiss().then();
        }
      });
    });
  }

  async loading_dismiss() {
    if (!this.is_loading) {
      return;
    }
    this.is_loading = false;
    this.current_loading_ctrl = undefined;
    await this.loading.dismiss();
  }

  private async create(type: 'error' | 'warn' | 'success', msg: string, duration = 3000,
                       position: 'middle' | 'top' | 'bottom' = 'middle') {
    let color = 'primary';
    if (type === 'error') {
      if (msg.length > 100) {
        msg = msg.substring(0, 100);
      }
      color = 'danger';
    } else if (type === 'warn') {
      color = 'warning';
    } else if (type === 'success') {
      color = 'success';
    }
    const toast = await this.toast_ctrl.create({
      // header: 'Toast header',
      message: msg,
      duration,
      position,
      cssClass: 'mc-' + type,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        }
      ],
      color
    });
    await toast.present();
  }

  error(msg: string, duration = 3000) {
    // 如果内容太长支取前100个
    if (msg.length > 100) {
      msg = msg.substring(0, 100);
    }
    return this.create('error', msg, duration);
  }

  confirm(msg: string) {
    return new Promise<boolean>(async resolve => {
      const ctl = await this.alert_ctrl.create({
        cssClass: 'mc-confirm',
        header: '请确认!',
        message: msg,
        buttons: [
          {
            text: '确认',
            cssClass: 'mc-alert-button-confirm',
            handler: () => {
              resolve(true);
            }
          }, {
            text: '取消',
            role: 'cancel',
            cssClass: 'mc-alert-button-cancel',
            handler: () => {
              resolve(false);
            }
          }
        ]
      });
      await ctl.present();
    });
  }

  async alert(msg: string, duration = 2000, position: 'middle' | 'top' | 'bottom' = 'middle') {
    const toast = await this.toast_ctrl.create({
      // header: 'Toast header',
      message: msg,
      duration,
      position,
      cssClass: 'mc-alert',
    });
    // toast.onDidDismiss(() => {
    //   console.log('Dismissed toast');
    // });
    await toast.present();
  }
}
