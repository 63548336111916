import { Injectable } from '@angular/core';
import { IStorage } from './interface';

@Injectable({ providedIn: 'root' })
export class McStorageService<T> implements IStorage<T> {

  constructor() {

  }

  get(key: string, _default?: string): T {
    return JSON.parse(localStorage.getItem(key) || 'null') || _default;
  }

  set(key: string, value: T | null): boolean {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

}
